//
// cta.scss
//

.bg-cta {
  //   background-image: url(../images/bg-3.jpg);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  background-position: center;
  z-index: 0;
}
