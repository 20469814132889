//
// team.scss
//
.team-box {
  .team-img {
    img {
      width: 130px;
    }
  }

  .team-content {
    border: solid 1px $gray-200;
    // min-height: 179px;
    // @media (max-width:992px) {
    //   min-height: 150px;
    // }

    // width: auto 240px;
    &:before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: $white;
      transform: rotate(45deg);
      border: solid 1px $gray-200;
      border-color: $gray-200 transparent transparent $gray-200;
      left: 0;
      right: 0;
      margin: 0px auto;
      margin-top: -36px;
    }
  }
}

.team-content-inner {
  // border: 2px solid red;
  width: 100%;
  min-height: 101px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    min-height: 90px;
  }
}

.teams_main {
  display: flex;
  flex-direction: column;
}
.team_top {
  padding-left: 20px;
  padding-right: 20px;
}
.team_bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.team_top_align {
  display: flex;
  justify-content: center;
  align-items: center;
  .team-content {
    width: 280px;
  }
}
.lighlight-bar {
  width: 150px;
  height: 1px;
  background: $primary;
  background: linear-gradient(to right, $primary, $primary, transparent);
  margin: 0 auto;
}
