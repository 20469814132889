//
// footer.scss
//
.footer-subscribe {
  border-bottom: 1px solid #ffd700;
}

.bg-footer {
  background-color: #ffffff;
  //   background-color: #f9f9f9;
  color: #ffffff;
  .footer-link {
    a {
      color: #808080;
      line-height: 38px;
      transition: all 0.5s;
      &:hover {
        color: #ffd700;
      }
    }
  }
  .footer-subcribe {
    input {
      padding: 12px 20px;
      width: 100%;
      font-size: 14px;
      border: none;
      outline: none !important;
      padding-right: 75px;
      padding-left: 15px;
      border-radius: 5px;
      font-weight: 500;
    }
    button {
      position: absolute;
      top: 0px;
      right: 0px;
      outline: none !important;
      border-radius: 0px 5px 5px 0px;
      font-size: 14px;
      padding: 11px 20px;
    }
    form {
      position: relative;
      max-width: 400px;
    }
  }
}

.footer-social {
  a {
    color: #808080;
    font-size: 18px;
    transition: all 0.5s;
    display: inline-block;
    &:hover {
      color: #ffd700;
    }
  }
}

.footer-alt {
  border-top: 1px solid #ffd700;
  background-color: #ffffff;
  color: #808080;
}
