//
// porthfolio.scss
//

.img-max-width {
  width: 25%;
}

.portfolio-title {
  font-size: 21px;
  word-spacing: 2px;
  font-weight: 700;
  letter-spacing: 1px;
}

.port-folio-sub-title {
  color: $muted;
  font-size: 17px;
  word-spacing: 1px;
  padding-top: 10px;
}

.container-filter {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
  li {
    list-style: none;
    display: inline-block;
  }
  a {
    display: block;
    font-size: 11px;
    border: 1px solid $gray-200;
    padding: 0px 15px;
    margin: 5px 3px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    line-height: 30px;
    transition: all 0.6s;
    border-radius: 3px;
    &:hover {
      color: $white !important;
      background-color: $primary;
      border: 1px solid $primary;
    }
    &.active {
      color: $white;
      background-color: $primary;
      border: 1px solid $primary;
    }
  }
}

.item-box {
  position: relative;
  overflow: hidden;
  display: block;
  a {
    display: inline-block;
  }
}

.item-box:hover {
  background: none repeat scroll 0 0 rgba(242, 242, 242, 0.9);
  .item-mask {
    opacity: 1;
    visibility: visible;
  }
  .item-caption {
    bottom: 30px;
    opacity: 1;
  }
  .item-container {
    opacity: 0.1;
    transform: scale(1.1);
    transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    width: 100%;
    height: 100%;
  }
}

.item-container {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  width: 100%;
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.item-mask {
  background: none repeat scroll 0 0 rgba(242, 242, 242, 0.9);
  position: static;
  transition: all 0.5s ease-in-out 0s;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  // height: 100%;
  // width: 100%;
  p {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1.5px;
    font-weight: 500;
    margin: 0;
    text-align: left;
  }
  .item-caption {
    position: absolute;
    bottom: -60px;
    left: 0px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
    transition: all 0.5s ease-in-out 0s;
    opacity: 0;
  }
}

.portfolio-head {
  h3 {
    font-size: 14px;
    letter-spacing: 3px;
    font-weight: 600;
  }
  p {
    font-size: 30px;
    font-weight: 600;
  }
}

// For hover effect in img
img.ril-image-current.ril__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
  touch-action: none;
}
