//
// Progress.scss
//
.bg-progress {
  background-image: url(../images/features/our-process.jpg);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  background-position: center;
}

//LINE (WHICH WILL BE CONVERTED TO ARROWS)
// .progress-border {
//   &:before {
//     content: "";
//     border: 1px solid rgba($white, 0.4%);
//     position: absolute;
//     width: 72%;
//     left: 64%;
//     top: 65px;
//   }
//   @media (max-width: 992px) {
//     &:before {
//       content: none;
//     }
//   }
// }

.progress-count {
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .circle {
      // display: flex;
      // justify-content: start;
      border: 2px solid rgba($white, 0.4%);
      border-radius: 0 100px 100px 0;
      width: 45px;
      height: 60px;
      padding-right: 7px;
      background-color: #ffffff;
      color: #ffd700;
    }
    .count-text {
      display: flex;
      justify-self: center;
      min-width: 130px;
    }
  }
  h3 {
    font-size: 1.3rem;
    border: 2px solid rgba($white, 0.4%);
    background: $primary;
    color: $white;
    // width: 80px;
    // height: 80px;
    line-height: 60px;
    // border-radius: 50%;
    margin: 0 auto;
  }
}

//DIMOND
.progress-content {
  position: relative;
  min-height: 121px;
  display: flex;
  justify-content: center;
  align-content: center;
  &:before {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    background-color: $white;
    transform: rotate(45deg);
    border-color: #e9ecef transparent transparent #e9ecef;
    left: 0;
    right: 0;
    margin: 0px auto;
    margin-top: -35px;
    border: solid 1px #e9ecef;
  }
}
.progress-content:before {
  // content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  transform: rotate(45deg);
  border: solid 1px #e9ecef;
  border-color: #e9ecef transparent transparent #e9ecef;
  left: 0;
  right: 0;
  margin: 0px auto;
  margin-top: -36px;
}

//LOWER BOX
.box-context {
  display: flex;
  justify-content: center;
  align-items: center;
}
